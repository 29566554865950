import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Styles from "./Suppliers.module.css"

const Suppliers: React.FC = () => {
    const {
        isarhund,
        bubeck,
        chiara,
        coldanddog,
        emmypepe,
        jakes,
        knuffelwuff,
        lunderland,
        maul,
        puppia,
    } = useStaticQuery(graphql`
        fragment SupplierLogo on File {
            childImageSharp {
                fluid(maxWidth: 150, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        query SuppliersQuery {
            isarhund: file(
                relativePath: { eq: "lieferanten/06-Logo-Isarhund-black.jpg" }
            ) {
                ...SupplierLogo
            }
            bubeck: file(
                relativePath: { eq: "lieferanten/Bubeck_Logo.jpg" }
            ) {
                ...SupplierLogo
            }
            chiara: file(
                relativePath: { eq: "lieferanten/Chiara.jpg" }
            ) {
                ...SupplierLogo
            }
            coldanddog: file(
                relativePath: { eq: "lieferanten/ColdandDog.png" }
            ) {
                ...SupplierLogo
            }
            emmypepe: file(
                relativePath: { eq: "lieferanten/Emmy&Pepe.jpg" }
            ) {
                ...SupplierLogo
            }
            jakes: file(
                relativePath: { eq: "lieferanten/Jakes.jpg" }
            ) {
                ...SupplierLogo
            }
            knuffelwuff: file(
                relativePath: { eq: "lieferanten/Knuffelwuff.jpg" }
            ) {
                ...SupplierLogo
            }
            lunderland: file(relativePath: { eq: "lieferanten/Lunderland.jpg" }) {
                ...SupplierLogo
            }
            maul: file(
                relativePath: { eq: "lieferanten/Maul_logo.jpg" }
            ) {
                ...SupplierLogo
            }
            puppia: file(relativePath: { eq: "lieferanten/puppia-c563b72f.jpg" }) {
                ...SupplierLogo
            }
        }
    `)

    const items = useMemo(
        () =>
            Object.entries({
                emmypepe: {
                    name: "Emmy & Pepe",
                    img: emmypepe.childImageSharp.fluid,
                },
                knuffelwuff: {
                    name: "Knuffelwuff",
                    img: knuffelwuff.childImageSharp.fluid,
                },
                bubeck: {
                    name: "Bubeck",
                    img: bubeck.childImageSharp.fluid,
                },
                puppia: {
                    name: "Puppia",
                    img: puppia.childImageSharp.fluid,
                },
                isarhund: {
                    name: "Isarhund",
                    img: isarhund.childImageSharp.fluid,
                },
                jakes: {
                    name: "Jakes",
                    img: jakes.childImageSharp.fluid,
                },
                coldanddog: {
                    name: "Cold and Dog",
                    img: coldanddog.childImageSharp.fluid,
                },
                maul: {
                    name: "Maul",
                    img: maul.childImageSharp.fluid,
                },
                lunderland: {
                    name: "Lunderland",
                    img: lunderland.childImageSharp.fluid,
                },
                chiara: {
                    name: "Chiara",
                    img: chiara.childImageSharp.fluid,
                },
            }),
        [
            isarhund,
            bubeck,
            chiara,
            coldanddog,
            emmypepe,
            jakes,
            knuffelwuff,
            lunderland,
            maul,
            puppia,
        ]
    )

    return (
        <div className="flex flex-wrap">
            {items.map(([id, item]) => (
                <div key={`clients-${id}`} className={Styles.client}>
                    {item.img && (
                        <Image
                            fluid={item.img}
                            title={item.name}
                            alt={item.name}
                            className={Styles.image}
                            loading="lazy"
                        />
                    )}
                </div>
            ))}
        </div>
    )
}

export default Suppliers
