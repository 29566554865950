import React, { ReactNode } from "react"

import Styles from "./RandomPolaroids.module.css"
import Polaroid from "../Polaroid/Polaroid"
import Section from "../Section/Section"
import { useStaticQuery, graphql } from "gatsby"

interface RandomPolaroidsProps<> {
    className?: string
    headline: ReactNode
    subtitle?: string
}

const ThreePolaroids: React.FC = () => {
    const {
        arthos,
        dackeltrio,
        luna,
        pearl,
        avanti,
        alegraSpike,
        casey,
        gina,
        graf,
        inge,
        leniSuseFine,
        lina,
        lottaBella,
        merle,
        sammy
    } = useStaticQuery(graphql`
        query RandomPolaroidsQuery {
            dackeltrio: file(relativePath: { eq: "dackel_trio.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            arthos: file(relativePath: { eq: "friends.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            luna: file(relativePath: { eq: "freunde/Luna.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            pearl: file(relativePath: { eq: "hund.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            avanti: file(relativePath: { eq: "avanti.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            alegraSpike: file(relativePath: { eq: "freunde/Alegra_Spike.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            casey: file(relativePath: { eq: "freunde/Casey.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            gina: file(relativePath: { eq: "freunde/Gina.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            graf: file(relativePath: { eq: "freunde/Graf.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            inge: file(relativePath: { eq: "freunde/Inge.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            lina: file(relativePath: { eq: "freunde/Lina.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            sammy: file(relativePath: { eq: "freunde/Sammy.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

	const images = [
        {
            image: arthos,
            alt: "Lindseys Freund Arthos",
            title: "Arthos",
            subtitle: "Arthos",
            quote: "",
        },
        {
            image: luna,
            alt: "Lindseys Freundin Luna",
            title: "Lindseys Freundin Luna",
            subtitle: "Luna",
            quote:
                "Lindsey´s langjährige Freundin, die uns gerne einen Besuch abstattet.",
        },
        {
            image: dackeltrio,
            alt: "Lindsey and Friends",
            title: "Lindsey and Friends",
            subtitle: "Lindsey and Friends",
            quote: "",
        },
        {
            image: pearl,
            alt: "Lindseys Freundin Pearl",
            title: "Pearl",
            subtitle: "Pearl",
            quote: "",
        },
        {
            image: avanti,
            alt: "Lindseys Freundin Avanti",
            title: "Avanti",
            subtitle: "Avanti",
            quote:
                "Mit dem neuen Zusatzfutter bekommt Avanti trotz Allergien alle Nährstoffe, die sie braucht und bleibt so auch im Alter fit und gesund.",
        },
        {
            image: alegraSpike,
            alt: "Lindseys Freunde Alegra und Spike",
            title: "Alegra und Spike",
            subtitle: "Alegra & Spike",
            quote:
                "Futterstammkunden, Allergiker und langjährige Freunde mit einer Leidenschaft unsere Softgeschirre von Puppia.",
        },
        {
            image: casey,
            alt: "Lindseys Freundin Casey",
            title: "Casey",
            subtitle: "Casey",
            quote: "Gassigeh Freundin, Urlaubsbegleitung und treue Kundin.",
        },
        {
            image: gina,
            alt: "Lindseys Freundin Gina",
            title: "Gina",
            subtitle: "Gina",
            quote:
                "Lindsey´s große Tante, hat schon fast das Ganze Lindsey and Friends Sortiment zuhause.",
        },
        {
            image: graf,
            alt: "Lindseys Freund Graf",
            title: "Graf",
            subtitle: "Graf",
            quote: "Lindsey´s Ziehpapa, großer Beschützer.",
        },
        {
            image: inge,
            alt: "Lindseys Freundin Inge",
            title: "Inge",
            subtitle: "Inge",
            quote:
                "Treue Stammkundin, seit wir ihr bei ihrer Ernährung helfen konnten.",
        },
        {
            image: lina,
            alt: "Lindseys Freundin Lina",
            title: "Lina",
            subtitle: "Lina",
            quote: "Stammkundin der ersten Stunde.",
        },
        {
            image: sammy,
            alt: "Lindseys Freund Sammy",
            title: "Sammy",
            subtitle: "Sammy",
            quote: "Gassigeh Freund, Urlaubsbegleitung und treuer Kunde.",
        },
        {
            image: leniSuseFine,
            alt: "Lindseys Freundinnen Leni, Suse und Fine",
            title: "Leni, Suse und Fine",
            subtitle: "Leni, Suse & Fine",
            quote:
                "Familie hält zusammen: Die drei wohnen über dem Laden und sind gemeinsam mit Lindsey aufgewachsen.",
        },
        {
            image: lottaBella,
            alt: "Lindseys Freundinnen Lotta und Bella",
            title: "Lotta und Bella",
            subtitle: "Lotta & Bella",
            quote:
                "Lieben unser Futter und Snacks. Stehen total auf unser Emmy und Pepe Sortiment.",
        },
        {
            image: merle,
            alt: "Lindseys Freundin Merle",
            title: "Merle",
            subtitle: "Merle",
            quote: " Lindsey´s Mitbewohnerin und beste Freundin.",
        },
    ]
	const i = Math.floor(Math.random() * images.length);
    var j = Math.floor(Math.random() * images.length);
    while (i === j) {
        j = Math.floor(Math.random() * images.length)
    }
    var k = Math.floor(Math.random() * images.length)
    while (i === k || j === k) {
        k = Math.floor(Math.random() * images.length)
    }

	return (
        <div className={Styles.polaroidWrapper}>
            <div className={`${Styles.polaroid} pb-5 md:pb-0`}>
                <div className={Styles.overlay}>
                    <div className={Styles.quote}>{images[i].quote}</div>
                </div>
                {images[i].image && (
                    <Polaroid
                        image={images[i].image.childImageSharp.fluid}
                        alt={images[i].alt}
                        title={images[i].title}
                        subtitle={images[i].subtitle}
                        className="m-auto"
                    />
                )}
            </div>
            <div className={`${Styles.polaroid} pb-5 md:pb-0`}>
                <div className={Styles.overlay}>
                    <div className={Styles.quote}>{images[j].quote}</div>
                </div>
                {images[j].image && (
                    <Polaroid
                        image={images[j].image.childImageSharp.fluid}
                        alt={images[j].alt}
                        title={images[j].title}
                        subtitle={images[j].subtitle}
                        className="m-auto"
                    />
                )}
            </div>
            <div className={`${Styles.polaroid} m-auto`}>
                <div className={Styles.overlay}>
                    <div className={Styles.quote}>{images[k].quote}</div>
                </div>
                {images[k].image && (
                    <Polaroid
                        image={images[k].image.childImageSharp.fluid}
                        alt={images[k].alt}
                        title={images[k].title}
                        subtitle={images[k].subtitle}
                        className="m-auto"
                    />
                )}
            </div>
        </div>
    )
}

const RandomPolaroids: React.FC<RandomPolaroidsProps> = ({
    className,
    headline
}) => {
    return (
        <Section className="bg-gray-300">
            <h2>{headline}</h2>
            <ThreePolaroids/>
        </Section>
    )
}

export default RandomPolaroids
