import React from "react"

import { PageType } from "../index"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import HeaderImage from "../components/HeaderImage/HeaderImage"
import TextImage from "../components/TextImage/TextImage"
import { useStaticQuery, graphql } from "gatsby"

import ImageDivider from "../components/ImageDivider/ImageDivider"
import Infos from "../components/Infos/Infos"
import RandomPolaroids from "../components/RandomPolaroids/RandomPolaroids"
import Section from "../components/Section/Section"
import News from "../components/News/News"
import Suppliers from "../components/Suppliers/Suppliers"
import Slider from "../components/Slider/Slider"
import Products from "../components/Products/Products"
const Index: PageType = ({ location }) => {
        const {
            friend,
            dackeltrio,
            spielzeug,
            sortiment,
            fotowand,
            laden,
            halsband,
            futter,
            accessoires,
            kaffee,
            kissen,
            leinen,
            futterecke,
            kissenecke,
            koerbchen
        } = useStaticQuery(graphql`
            query IndexQuery {
                sortiment: file(relativePath: { eq: "sortiment.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1240, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                fotowand: file(relativePath: { eq: "fotowand.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1240, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                friend: file(relativePath: { eq: "friend.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                dackeltrio: file(relativePath: { eq: "dackel_trio.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                spielzeug: file(relativePath: { eq: "spielzeug.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                halsband: file(relativePath: { eq: "produkte/halsbaender.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                futter: file(relativePath: { eq: "produkte/futter.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                accessoires: file(relativePath: { eq: "produkte/accessoires.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                kaffee: file(relativePath: { eq: "produkte/kaffeeecke.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                kissen: file(relativePath: { eq: "produkte/kissen-koerbchen.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                leinen: file(relativePath: { eq: "produkte/leinen.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 266, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                laden: file(relativePath: { eq: "laden.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1240, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                futterecke: file(relativePath: { eq: "laden/futterecke.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1240, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                kissenecke: file(relativePath: { eq: "laden/kissenecke.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1240, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                koerbchen: file(relativePath: { eq: "laden/koerbchen.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1240, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `)


    return (
        <Layout location={location}>
            <Seo title="Lindsey and friends" description="" />
            <HeaderImage
                headline={
                    <>
                        Herzlich Willkommen
                        <br />
                        bei Lindsey &amp; friends
                    </>
                }
                background={laden.childImageSharp.fluid}
            />
            <Infos />
            <span id="ueber"></span>
            <TextImage
                imageback={friend.childImageSharp.fluid}
                altback="Freund"
                titleback="Freund"
                imagefront={dackeltrio.childImageSharp.fluid}
                altfront="Dackeltrio"
                titlefront="Lindsey,..."
                headline="Hundebedarf, Accessoires und Leckeres aus dem Herzen der Stadt Spenge"
                textOrder="order-first"
            >
                <p>
                    Bei Lindsey &amp; friends werden Hundeträume wahr. Wir
                    bieten Ihnen eine großes Auswahl an Hundebedarf an und
                    freuen uns über jeden Gast, den wir an unserer Fotowand
                    verewigen dürfen. Getreu dem Motto: ein jeder zufriedene
                    Kund ist ein willkommener wiederkehrende Freund, halten wir
                    für Sie und Ihre Liebsten eine Shooting-Ecke bereit.
                </p>
            </TextImage>
            <span id="aktuelles"></span>
            <Section className="bg-gray-300">
                <h2>
                    Regelmäßig gibt es bei uns was Neues &ndash; ob Sale, Rabatt
                    oder Neue Waren &ndash; zu entdecken:
                </h2>
                <News />
            </Section>
            <Slider
                items={[
                    {
                        id: 1,
                        imagePath: sortiment.childImageSharp.fluid,
                        alt: "",
                        title: "Das Sortiment von Lindsey and friends",
                    },
                    {
                        id: 2,
                        imagePath: futterecke.childImageSharp.fluid,
                        alt: "",
                        title: "Das Sortiment von Lindsey and friends",
                    },
                    {
                        id: 3,
                        imagePath: kissenecke.childImageSharp.fluid,
                        alt: "",
                        title: "Das Sortiment von Lindsey and friends",
                    },
                    {
                        id: 4,
                        imagePath: koerbchen.childImageSharp.fluid,
                        alt: "",
                        title: "Das Sortiment von Lindsey and friends",
                    },
                ]}
            />
            <span id="hundebedarf"></span>
            <Section className="bg-white">
                <Products
                    sectionHeadline="Fachgeschäft für Hundebedarf"
                    headtext="Seit April 2020 finden Sie uns am Bücherplatz 4 in Spenge.
                    Im 80 Quadratmeter großen Hundeshop bieten wir eine breite
                    Auswahl an Produkten für kleine und große Hunde:"
                    items={[
                        {
                            id: "futter",
                            imagePath: futter.childImageSharp.fluid,
                            alt: "Hochwertiges Nass- und Trockenfutter",
                            title: "Hundefutter",
                            headline: "Hochwertiges Nass- und Trockenfutter",
                            description:
                                "Satt, gesund und zufrieden – Wir bieten Ihrem Hund das Futter, welches seinen Bedürfnissen entspricht. Ganz gleich ob sportlicher Agility Teilnehmer, gemütlicher Couch-Anwärmer oder allergiebelasteter Bürohund, wir finden die passende Ernährung für Ihr vierbeiniges Familienmitglied.",
                        },
                        {
                            id: "halsband",
                            imagePath: halsband.childImageSharp.fluid,
                            alt: "Schicke Halsbänder für jede Nackenweite",
                            title: "Hundehalsbänder",
                            headline: "Schicke Halsbänder für jede Nackenweite",
                            description:
                                "Schmal oder breit? Dezent oder auffällig? Allrounder oder eins für jeden Tag in der Woche? Bei uns wartet eine große Auswahl an Halsbändern darauf Ihren Hund zu schmücken und sicher zu führen.",
                        },
                        {
                            id: "leinen",
                            imagePath: leinen.childImageSharp.fluid,
                            alt:
                                "Schöne Leinen in verschiedenen Längen und Ausführungen",
                            title: "Hundeleinen",
                            headline:
                                "Schöne Leinen in verschiedenen Längen und Ausführungen",
                            description:
                                "Natürlich finden Sie bei uns auch die passende Leine zum neuen Lieblingshalsband. Von wasserabweisender Laufleine bis stylischer City-Leine, bei uns warten vielfältige Designs in unterschiedlichen Längen darauf Sie und Ihren felligen Freund bei Spaziergängen begleiten zu dürfen.",
                        },
                        {
                            id: "kissen",
                            imagePath: kissen.childImageSharp.fluid,
                            alt:
                                "Kissen und Körbchen für kleine und Große Schläfchen",
                            title: "Kissen und Körbchen",
                            headline:
                                "Kissen und Körbchen für kleine und große Schläfchen",
                            description:
                                "Alle Viere von sich strecken und danach gut erholt in neue Abenteuer starten! Wir wissen wie wichtig ein gesunder Schlaf und die passende Unterlage ist – nicht nur bei älteren Hunden. Unsere Liegeplätze bieten auch tagsüber den idealen Ruheplatz zum Träumen und Erholen.",
                        },
                        {
                            id: "spielzeug",
                            imagePath: spielzeug.childImageSharp.fluid,
                            alt:
                                "Spielzeuge für die kleinen und großen Auszeiten",
                            title: "Spielzeug",
                            headline:
                                "Spielzeuge für die kleinen und großen Auszeiten",
                            description:
                                "Das gemeinsame Spiel ist nicht nur wichtiger Teil der Erziehung und stärkt die emotionale Bindung zwischen Mensch und Hund, es kann auch helfen Stress abzubauen und die Gesundheit aufrecht zu erhalten. Stöbern Sie durch Lern-, Kau- und Kuschelspielzeug. Auch für echte „Wasserratten“ und „Zerstörer“ haben wir das passende auf Lager.",
                        },
                        {
                            id: "accessoires",
                            imagePath: accessoires.childImageSharp.fluid,
                            alt: "Accessoires für Frauchen und Herrchen",
                            title: "Accessoires",
                            headline: "Accessoires für Frauchen und Herrchen",
                            description:
                                "Gönnen Sie sich selbst etwas Gutes. Extra für Herrchen und Frauchen haben wir eine Auswahl an Kleidungsstücken mit lustigen Beschriftungen, humorvolle Fußmatten machen Ihrem Besuch gleich klar, wer im Haus das Sagen hat und wärmende Westen schützen Sie vor Kälte. Auch als Geschenk für befreundete Hundebesitzer kommen unsere Accessoires immer wieder gut an.",
                        },
                        {
                            id: "kaffee",
                            imagePath: kaffee.childImageSharp.fluid,
                            alt:
                                "Lassen Sie sich bei einem Kaffee ausführlich beraten",
                            title: "Kaffee-Ecke",
                            headline:
                                "Lassen Sie sich bei einem Kaffee ausführlich beraten",
                            description:
                                "In der Ruhe liegt die Kraft. Damit alles passt nehmen wir uns gern die Zeit für Sie und Ihren Hund. Lernen Sie uns und unser Sortiment bei einer Tasse Kaffee kennen und lieben. Wir freuen uns auf Sie.",
                        },
                    ]}
                />
            </Section>
            <span id="fotowand"></span>
            <ImageDivider
                image={fotowand.childImageSharp.fluid}
                alt="Die Fotowand bei Lindsey and friends"
                title="Die Fotowand bei Lindsey and friends"
            />
            <RandomPolaroids headline={<>Lindseys best friends:</>} />
            <Section className="bg-white" sectionsmall>
                <Suppliers />
            </Section>
        </Layout>
    )
}

export default Index
