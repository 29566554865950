import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Styles from "./News.module.css"


const News: React.FC = () => {
    const { allStrapiAktuelles } = useStaticQuery(graphql`
        query NewsQuery {
            allStrapiAktuelles(
                sort: { fields: created_at, order: DESC }
                limit: 1
                ) {
                edges {
                    node {
                        id
                        Titel
                        Text
                        Bild {
                            publicURL
                        }
                        created_at
                    }
                }
            }
        }
    `)

    const strapiAktuelles = allStrapiAktuelles?.edges[0]?.node

    if (!strapiAktuelles) {
        return null;
    }

    const date = new Date(strapiAktuelles.created_at)
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)

    return (
        <article className={Styles.article}>
            <div
                className={Styles.bgImage}
                style={{
                    backgroundImage:
                        "url(" + strapiAktuelles.Bild.publicURL + ")",
                }}
            ></div>
            <div className={Styles.textWrapper}>
                <div className="w-full">
                    <h3>{strapiAktuelles.Titel}</h3>
                    <p className="py-8">{strapiAktuelles.Text}</p>
                    <p>
                        Verfasst am: {day}.{month}.{date.getFullYear()}
                    </p>
                </div>
            </div>
        </article>
    )
}

export default News

